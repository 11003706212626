/** @jsx jsx */
import React, { Fragment } from 'react';
import { Box, Heading, Text, jsx, Badge } from 'theme-ui';
import { StaticQuery, graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Carousel from '../../carousel/carousel';
import CategoryBlock from '../../category-card/secondary/secondary';
import styles from './slider.style';
import ProductCardWithLink from '../../product-card/with-link';

/*type PropsType = {
	path: string;
	title: any;
	thumbnail: string;
	count: number;
};*/

//const CategoryBlocks: React.FC<PropsType> = ({ path, title, thumbnail, count }) => {
const CategoryBlocks: React.FC = () => {
	const responsive = {
		xlg: {
			breakpoint: { max: 100000, min: 1440 },
			items: 6,
			partialVisibilityGutter: 40,
		},
		lg: {
			breakpoint: { max: 1439, min: 1200 },
			items: 5,
			partialVisibilityGutter: 40,
		},
		md: {
			breakpoint: { max: 1199, min: 768 },
			items: 3,
			partialVisibilityGutter: 30,
		},
		sm: {
			breakpoint: { max: 767, min: 0 },
			items: 2,
			partialVisibilityGutter: 30,
		},
	};
	
	const getPrice = (price: any) =>
		Intl.NumberFormat(undefined, {
			currency: price.currencyCode,
			minimumFractionDigits: 2,
			style: 'currency',
		}).format(parseFloat(price && price.amount ? price.amount : 0));	

	return (
		<StaticQuery<GatsbyTypes.Query>
			query={`${ProductsQuerys}`}
			render={( data ) => {
				//console.log("data:", data)
				const keys = Object.keys(data);
				return (
					<Fragment>
						{ 
							keys.map((key, index) => {
								const products = data[key]?.products;
								const featured = (data[key].title == "Whole Spices" || data[key].title == "Spice Powders") ? true : false;
								const diwaliOffer = data[key].title == "XXXX" ? true : false; //remove diwali offer
								return (
									<Fragment key={key} >
										<Box sx={styles.wrapper} id={data[key].handle}>
										<Box sx={styles.header}>
											<Link
												activeClassName="active"
												to={`/collection/${data[key].handle}`}
											>										
												<Heading as="h2">{data[key].title}{featured && (<Badge variant='red' mt={-1}>New</Badge>)}{diwaliOffer && (<Badge variant='outline' mt={-1}>Diwali Offer</Badge>)}</Heading>
												
											</Link>
										</Box>
											<Carousel ssr={true} responsive={responsive} gutter="10px" partialVisible={true} >
												{products.map((product: any) => {
													const {
														id,
														title,
														handle,
														shopifyId,
														variants,
														availableForSale,
														images: [firstImage],
														variants: [firstVariant],
														tags,
													} =  product;
													return (
														<Fragment key={id}>
															<ProductCardWithLink
																title={title}
																path={`/product/${handle}`}
																shopifyId={shopifyId}
																availableForSale={availableForSale}
																price={getPrice(firstVariant?.priceV2)}
																thumbnail={firstImage?.localFile?.childImageSharp?.fluid}
																variants={variants}
																tags={tags}
															/>
														</Fragment>
													);
												})}							
											</Carousel>					    
										</Box>
									</Fragment>
								  )
							   })
							}
					</Fragment>						
				);									
			}}
		/>	
	);
};

const ProductsQuerys = graphql`
	query {	
		burfivarieties: shopifyCollection(handle: { eq: "burfi-varieties" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}		
		millets: shopifyCollection(handle: { eq: "millets" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		dalslegumes: shopifyCollection(handle: { eq: "dals-legumes" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		rices: shopifyCollection(handle: { eq: "rices" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		coldpressedoils: shopifyCollection(handle: { eq: "cold-pressed-oils" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		nuts: shopifyCollection(handle: { eq: "nuts" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		milletflours: shopifyCollection(handle: { eq: "millet-flours" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		naturalsugars: shopifyCollection(handle: { eq: "natural-sugars" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		instanthealthymix: shopifyCollection(handle: { eq: "instant-healthy-mix" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		pohavarieties: shopifyCollection(handle: { eq: "poha-varieties" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		podivarieties: shopifyCollection(handle: { eq: "podi-varieties" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		traditionalrices: shopifyCollection(handle: { eq: "traditional-rices" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		milletsgrain: shopifyCollection(handle: { eq: "millets-grain" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		flourssooji: shopifyCollection(handle: { eq: "flours-sooji" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		wholespices: shopifyCollection(handle: { eq: "whole-spices" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		spicepowders: shopifyCollection(handle: { eq: "spice-powders" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		milletspasta: shopifyCollection(handle: { eq: "millets-pasta" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		milletsnoodles: shopifyCollection(handle: { eq: "millets-noodles" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}	
		milletsvermicellisevai: shopifyCollection(handle: { eq: "millets-vermicelli-sevai" }) {
				title
				handle
				image {
					id
					src
					localFile {
						childImageSharp {
							fluid(maxWidth: 910) {
								...GatsbyImageSharpFluid_withWebp_tracedSVG
							}
						}
					}
				}
				products {
					id
					title
					handle
					createdAt
					shopifyId
					availableForSale
					tags
					variants {
						id
						price {
							amount
							currencyCode
						}
						priceV2 {
							amount
							currencyCode
						}
						shopifyId
						availableForSale
					}
					images {
						id
						originalSrc
						localFile {
							childImageSharp {
								fluid(maxWidth: 910, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}														
	}
`;

export default CategoryBlocks;
