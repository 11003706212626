const styles = {
	wrapper: {
		backgroundColor: 'white',
		padding: 30,
		marginBottom: 30,
		'.carousel-button-group button': {
			top: 'calc(50% - 34px)',
		},
	},
	header: {
		marginBottom: ['30px', '40px'],
		h2: {
			color: 'primary',
			fontSize: ['17px', 2],
		},
		p: {
			fontSize: '14px',
		},		
		a: {
			textDecoration: 'none',
			color: 'primary' ,
			fontSize: '14px',
			position: 'relative',
			'@media only screen and (min-width: 1081px)': {
				'&:hover': {
					color: 'black',
					textDecoration: 'underline',
				},
				'&.active': {
					color: 'black',
					fontWeight: '600',
				},
			},
			'@media only screen and (max-width: 1080px)': {
				'&:hover, &.active': {
					color: 'black',
					fontWeight: '600',
				},
			},
		},		
	},
};

export default styles;
